import { globalSettings } from "services/globalSettings/globalSettingsService";
export default function DesktopNavbarContentStyle() {
  return (
    <style jsx global>{`
      .navigation-container {
        position: relative;
        background-color: ${globalSettings.navigation
          .navigationBackgroundColor} !important;
        padding: ${`${globalSettings.navigation.spaceY}px ${
          globalSettings.navigation.spaceX > 15
            ? 15
            : globalSettings.navigation.spaceX
        }px`};
        .MuiContainer-root {
          // general styling for navbar
          position: unset;
          padding: 0;

          background-color: ${globalSettings.navigation
            .baseLayerBackgroundColor};

          .MuiToolbar-root {
            min-height: 0;
          }
          // navbar brand
          .navigation-brand {
            margin-left: ${globalSettings.navigation.navLogoMarginX}px;
            margin-right: ${globalSettings.navigation.navLogoMarginX}px;
            margin-top: ${globalSettings.navigation.navLogoMarginY}px;
            margin-bottom: ${globalSettings.navigation.navLogoMarginY}px;
          }

          // desktop navbar button styling (menu links and dropdown toggles)
          // only layer 1
          .MuiButton-root.base-level {
            // display: flex;
            text-transform: none;
            text-align: center;
            word-break: break-all;
            font-family: ${globalSettings.primaryfont.primaryfontname};
            border-radius: 0;

            // layer 1 element margin
            margin-top: ${globalSettings.navigation.layer1ElementMarginY}px;
            margin-bottom: ${globalSettings.navigation.layer1ElementMarginY}px;
            margin-left: ${globalSettings.navigation.layer1ElementMarginX}px;
            margin-right: ${globalSettings.navigation.layer1ElementMarginX}px;

            // element padding
            padding-left: ${globalSettings.navigation.navbarElementPaddingX}px;
            padding-right: ${globalSettings.navigation.navbarElementPaddingX}px;
            padding-top: ${globalSettings.navigation.navbarElementPaddingY}px;
            padding-bottom: ${globalSettings.navigation
              .navbarElementPaddingY}px;

            font-size: ${globalSettings.navigation.fontSize}px;
            font-weight: ${globalSettings.navigation.fontWeight};
            color: ${globalSettings.navigation.baseLayerElementFontColor};
            background-color: ${globalSettings.navigation
              .baseLayerElementBackgroundColor};

            .MuiSvgIcon-root {
              color: ${globalSettings.navigation.baseLayerElementFontColor};
              align-self: center;
            }

            border-bottom: ${globalSettings.navigation
              .layer1BottomBorderOnActive ||
            globalSettings.navigation.layer1BottomBorderOnHover
              ? `${globalSettings.navigation.layer1BottomBorderHeight}px solid transparent`
              : "none"};

            &:hover,
            &.open {
              background-color: ${globalSettings.navigation
                .hoverElementBackgroundColor};
              color: ${globalSettings.navigation.hoverElementFontColor};
              border-bottom: ${globalSettings.navigation
                .layer1BottomBorderOnHover
                ? `${globalSettings.navigation.layer1BottomBorderHeight}px solid ${globalSettings.navigation.layer1BottomBorderColorHover}`
                : globalSettings.navigation.layer1BottomBorderOnActive
                ? `${globalSettings.navigation.layer1BottomBorderHeight}px solid transparent`
                : "none"};
              .MuiSvgIcon-root {
                color: ${globalSettings.navigation.hoverElementFontColor};
              }
            }
            &.active {
              background-color: ${globalSettings.navigation
                .activeElementBackgroundColor};
              color: ${globalSettings.navigation.activeElementFontColor};
              border-bottom: ${globalSettings.navigation
                .layer1BottomBorderOnActive
                ? `${globalSettings.navigation.layer1BottomBorderHeight}px solid ${globalSettings.navigation.layer1BottomBorderColorActive}`
                : globalSettings.navigation.layer1BottomBorderOnHover
                ? `${globalSettings.navigation.layer1BottomBorderHeight}px solid transparent`
                : "none"};
              .MuiSvgIcon-root {
                color: ${globalSettings.navigation.activeElementFontColor};
              }
            }
          }
        }

        /* @media (min-width: ${globalSettings.responsive.breakpointmobile}px) {
          padding: ${`${
          globalSettings.navigation.spaceY *
          globalSettings.layout.scalingfactorSpaceYTablet
        }px ${
          globalSettings.navigation.spaceX *
          globalSettings.layout.scalingfactorSpaceXTablet
        }px`};
        } */
        @media (min-width: ${globalSettings.responsive.breakpointdesktop}px) {
          padding: ${`${globalSettings.navigation.spaceY}px ${globalSettings.navigation.spaceX}px`};
          .MuiContainer-root {
            max-width: ${globalSettings.navigation.centerNavigationWithImage
              ? globalSettings.navigation.navigationCenteredMaxWidth
              : "100%"};
          }
        }
        @media (min-width: ${globalSettings.responsive.breakpoint2k}px) {
          padding: ${`${
            globalSettings.navigation.spaceY *
            globalSettings.layout.scalingfactorSpaceY2kplus
          }px ${
            globalSettings.navigation.spaceX *
            globalSettings.layout.scalingfactorSpaceX2kplus
          }px`};
        }
      }
    `}</style>
  );
}
