import dynamic from "next/dynamic";
import { Box, AppBar, Container, Toolbar } from "@mui/material";
import { useSelector } from "react-redux";
import { NAVIGATION_MAIN_NAME } from "utils/constants";
import NavbarStyle from "./navbarStyle";

const DesktopNavbarContent = dynamic(() =>
  import("./desktop/desktopNavbarContent")
);
const MobileNavbarContent = dynamic(() =>
  import("./mobile/mobileNavbarContent")
);

/**
 * These are simplyfied notes about the structure of the available navigations.
 * This does not show the exact component tree but gives a brief
 * overview about the structure with the navigation related components
 *
 * --- mobile navigation structure (simplyfied): ---
 *
 * // NOTE:
 * // <DrawerListMenuItem> gets called recursively until no children are
 * // present in the current navitem
 *
 *
 *  <MobileNavbarContent>
 *    <SwipeableDrawer>
 *      <DrawerContent>
 *        // layer1
 *        <DrawerListMenuItem>
 *          <DrawerListMenuItemComponent> // (Link or collapsable)
 *        </DrawerListMenuItem>
 *        // layer1
 *        <DrawerListMenuItem>
 *          <DrawerListMenuItemComponent> // (Link or collapsable)
 *        </DrawerListMenuItem>
 *            <Collapse>
 *              // layer2
 *              <DrawerListMenuItem>
 *                <DrawerListMenuItemComponent> //  (first element is Link of parent)
 *              </DrawerListMenuItem>
 *              <DrawerListMenuItem>
 *                <DrawerListMenuItemComponent> //  (Link or collapsable)
 *              </DrawerListMenuItem>
 *                  <Collapse>
 *                      // layer3
 *                      <DrawerListMenuItem>
 *                        <DrawerListMenuItemComponent> //  (first element is Link of parent)
 *                      </DrawerListMenuItem>
 *                      <DrawerListMenuItem>
 *                        <DrawerListMenuItemComponent> //  (Link or collapsable)
 *                      </DrawerListMenuItem>
 *                  </Collapse>
 *            </Collapse>
 *        // layer1
 *        <DrawerListMenuItem>
 *          <DrawerListMenuItemComponent> // (Link or collapsable)
 *        </DrawerListMenuItem>
 *      ...
 *
 *
 * --- desktop dropdown navigation (simplyfied): ---
 *
 *  <DesktopNavbarContent>
 *    <DesktopNavbarWithDropdowns>
 *      <Button/Link> (layer1 button=dropdown link=no children (no dropdown))
 *      <Button/Link> (layer1)
 *      <Button/Link> (layer1)
 *      <Button/Link> (layer1)
 *      ...
 *
 *      // NOTE:
 *      // invisible dropdowns: all available dropdowns are invisible in the dom tree from the beginning
 *      // if they toggle their visibility they will be shown at the right from the button (anchorEl)
 *      // layer2/layer3 dropdowns
 *      // gets visible if a layer1 button that has children elements was clicked
 *      // if anchorEl === null <DesktopNavBarSubmenu> is hidden (anchorEl={anchorElMenus[outerIndex]})
 *      <DesktopNavBarSubmenu>
 *          <Menu>
 *              <Link> // first element has the Link of the trigger button
 *              <Link> // layer 2
 *          <NestedMenuItem> // layer 3 dropdown menu
 *              <DesktopNavbarDropdownMenuItem>
 *                  <Link> // first element has the Link of the NestedMenuItem toggle
 *                  <Link> // layer3
 *      ...
 *
 * --- dekstop hover navigation (simplyfied): ---
 * <DesktopNavbarContent>
 *  <FullScreenHoverNavigation>
 *    <Box>
 *      <Link> // layer1
 *      <Link> // layer1
 *      <Link> // layer1
 *
 *  // NOTE:
 *  // invisible div that gets visible on hover with layer2/3 links
 *  // in this hover div all layer2/3 links are shown, it does not matter
 *  // on which layer1 navitem you hover
 *
 *  //layer2 / layer3
 *  <div className="visible nav-hover-drawer">
 *    <div>
 *      <div className="layer2 nav-item">
 *        <Link> // layer 2
 *    <div>
 *      <div className="layer2 nav-item">
 *        <Link> // layer 2
 *      <div className="layer3 nav-item">
 *        <Link> // layer 3
 *      <div className="layer3 nav-item">
 *        <Link> // layer 3
 *    <div>
 *      <div className="layer2 nav-item">
 *        <Link> // layer 2
 *      <div className="layer3 nav-item">
 *        <Link> // layer 3
 *    ...
 */

const Navbar = ({ navName }) => {
  const navigation = useSelector((state) => {
    if (!state.navigation.navigations) {
      return null;
    }

    const searchedNav = state.navigation.navigations.find(
      (navigationElement) => navigationElement.name === navName
    );
    if (searchedNav) {
      return searchedNav;
    }

    const fallBackMainNav = state.navigation.navigations.find(
      (navigationElement) => navigationElement.name === NAVIGATION_MAIN_NAME
    );
    if (fallBackMainNav) {
      return fallBackMainNav;
    }

    // return null if no navigation was found so no navigation is rendered then
    return null;
  });

  const navigationWithoutUnpublishedPages = () => {
    const clonedNavigation = JSON.parse(JSON.stringify(navigation));
    if (clonedNavigation !== null || clonedNavigation.pages?.length > 0) {
      const filteredPages = [];
      // check first lvl
      clonedNavigation.pages.forEach((pageContainer) => {
        if (pageContainer.externalUrl || pageContainer.page?.published) {
          filteredPages.push(pageContainer);
        }

        // check second lvl
        if (pageContainer.subPages?.length > 0) {
          pageContainer.subPages = pageContainer.subPages.filter(
            (secondLvlPage) =>
              secondLvlPage.externalUrl || secondLvlPage.page.published
          );

          // check third lvl
          pageContainer.subPages.forEach((subPage) => {
            if (subPage.subPages?.length > 0) {
              subPage.subPages = subPage.subPages.filter(
                (thirdlvlPage) =>
                  thirdlvlPage.externalUrl || thirdlvlPage.page.published
              );
            }
          });
        }
      });
      clonedNavigation.pages = filteredPages;
    }
    return clonedNavigation;
  };

  return navigation ? (
    <AppBar
      color="inherit"
      position="static"
      className="navigation-container"
      id="navbar"
    >
      <Container maxWidth="xl">
        <Toolbar sx={{ position: "unset", maxWidth: "100%" }} disableGutters>
          <Box className="mobile-navigation-container">
            <MobileNavbarContent
              navigation={navigationWithoutUnpublishedPages()}
            />
          </Box>
          <Box className="navigation-desktop-container">
            <DesktopNavbarContent
              navigation={navigationWithoutUnpublishedPages()}
            />
          </Box>
        </Toolbar>
      </Container>
      <NavbarStyle />
    </AppBar>
  ) : null;
};
export default Navbar;
